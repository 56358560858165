body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}






@keyframes boomerang {
    000% { background-position:    0%    0% }
    005% { background-position: -100%    0% }
    010% { background-position: -200%    0% }
    015% { background-position: -300%    0% }
    020% { background-position:    0% -100% }
    025% { background-position: -100% -100% }
    030% { background-position: -200% -100% }
    035% { background-position: -300% -100% }
    040% { background-position:    0% -200% }
    045% { background-position: -100% -200% }
    050% { background-position: -200% -200% }
    055% { background-position: -100% -200% }
    060% { background-position:    0% -200% }
    065% { background-position: -300% -100% }
    070% { background-position: -200% -100% }
    075% { background-position: -100% -100% }
    080% { background-position:    0% -100% }
    085% { background-position: -300%    0% }
    090% { background-position: -200%    0% }
    095% { background-position: -100%    0% }
    100% { background-position:    0%    0% }
}

.boomerang { animation: boomerang 1s steps(1) infinite; background-size: 400% !important; }

.snap {
    width: 720px;
    height: 960px;
    margin: 0 auto;
    position: relative;
    background-position:0 0;
    background-size: 100%;
}
